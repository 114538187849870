<template>
  <v-card class="text-center">
    <v-overlay
      v-if="overlay || overlayMessage"
      color="secondary"
      opacity="0.7"
      absolute
      z-index="4"
    >
      <v-sheet
        v-if="overlayMessage"
        class="rounded-lg pa-1 px-4 mx-auto transition-swing accent subtitle-1"
        width="100%"
        v-text="overlayMessage"
      />
    </v-overlay>

    <v-toolbar
      dense
      flat
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="managementProjectTitle" />
      <v-spacer />
    </v-toolbar>

    <v-card-text class="grey lighten-4 py-0">
      <project-date-card
        v-if="showProjectDates"
        :value="currentPlayer"
        :current-round="game.currentRound"
        @change-week-end-planned="$emit('change-week-end-planned', $event)"
      />
      <v-row no-gutters align="center">
        <v-col v-if="ccpm" cols="auto">
          <ag-fever-chart
            :value="currentPlayer"
            :size="{ height: 180, width: 300 }"
          />
        </v-col>
        <v-col>
          <gantt-chart
            :value="currentPlayer.gantt"
            :height="25"
            :resource-list="game.resourceList"
          />
          <resource-list-row :value="game.resourceList" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AgFeverChart from 'src/components/common/AgFeverChart'
import GanttChart from './GanttChart'
import ResourceListRow from './ResourceListRow'
import ProjectDateCard from './ProjectDateCard'

export default {
  name: 'ProjectMilestonesCard',
  components: {
    AgFeverChart,
    GanttChart,
    ResourceListRow,
    ProjectDateCard,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
    showProjectDates: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    overlayMessage: {
      type: String,
      default: '',
    },
    ccpm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    managementProjectTitle () {
      if (this.ccpm && this.currentPlayer.weekStartReal) {
        return this.$t('multiproject.common.managementStartedProjectTitle', {
          week: this.currentPlayer.weekStartReal,
        })
      }
      const { projectName } = this.currentPlayer
      return this.$t('multiproject.common.managementProjectTitle', { projectName })
    },
  },
}
</script>
