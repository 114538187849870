<template>
  <v-container>
    <v-alert
      class="text-center text-h5 mb-1"
      outlined
      text
      dense
      color="primary"
      v-text="$t('multiproject.common.currentWeek', { week: game.currentRound })"
    />
    <project-milestones-card
      :game="game"
      :player-id="playerId"
      :show-project-dates="showProjectDates"
      :overlay-message="overlayMessage"
      :ccpm="ccpm"
      @change-week-end-planned="$emit('change-week-end-planned', $event)"
    />

    <router-view
      v-if="readyToRender"
      :game="game"
      :player-id="playerId"
      :ccpm="ccpm"
    />
  </v-container>
</template>

<script>
import AVAILABLE_STATUS from '../availableStatus'

import ProjectMilestonesCard from '../components/ProjectMilestonesCard'

export default {
  name: 'InProgressContainer',
  components: {
    ProjectMilestonesCard,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
    readyToRender: {
      type: Boolean,
      default: false,
    },
    ccpm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    currentStatus () {
      return this.currentPlayer.status
    },
    showProjectDates () {
      return !this.ccpm && [
        AVAILABLE_STATUS.resourcesSelection,
        AVAILABLE_STATUS.waitForResourcesCapacity,
        AVAILABLE_STATUS.capacityReceived,
        AVAILABLE_STATUS.mustChangeEndDate,
        AVAILABLE_STATUS.waitToStartNewWeek,
      ].includes(this.currentStatus)
    },
    overlayMessage () {
      if (this.currentStatus === AVAILABLE_STATUS.projectFinished) {
        const duration = this.currentPlayer.projectDuration
        const durationText = this.$tc('multiproject.projectFinished.done', duration, { duration })

        if (this.ccpm) {
          return durationText
        }

        const changes = this.currentPlayer.historyWeekEndPlanned.length
        const changesText = this.$tc('multiproject.projectFinished.changes', changes, { changes })

        return `${durationText}, ${changesText}`
      }

      return ''
    },
  },
}
</script>
